import React from 'react';

import Layout from '../components/layout';
import DefaultHero from '../components/DefaultHero';

const IndexPage = () => 
    <Layout>
            <section className="triangle has-text-centered">
                <div className="title is-3">
                    Ihr Alarmanlagen-Rechner
                </div>
                <div className="subtitle is-5">
                    Vergleichen Sie In wenigen Minuten bis zu 3 Alarmanlagen-Angebote
                </div>
            </section>
            <DefaultHero/>
    </Layout>

export default IndexPage;
